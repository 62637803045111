import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import { SurveillanceModeEnum } from './modules/Surveillance/Models/Enums';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter, Navigate, useParams } from 'react-router-dom';
import axios from 'axios';

import App from './App';
import Auth0Wrapper from './auth0-wrapper';
import Surveillance from './modules/Surveillance';
import ReportLinkExpiredPage from 'modules/Surveillance/Components/ReportLinkExpired';
import SanctionsPage from 'modules/Sanctions';
import BalticRates from 'modules/Rates/BalticRates';
import RateGrids from 'modules/Rates/Grids';
import CommoditiesData from 'modules/Rates/Commodities';
import SecureEmailGeneratePage from 'modules/SecureEmail/Components/SecureEmailGeneratePage';
import SecureEmailPage from 'modules/SecureEmail';
import DistanceCalcPage from 'modules/DistanceCalc';
import CargoFlows from 'modules/CargoFlows';
import InternalTesting from 'modules/Internal';
import UserSettingsPlayground from 'modules/Internal/UserSettingsPlayground';
import ErrorPage from 'components/Errors/ErrorPage';
import Reports from 'modules/Reports';
import VortexaRates from 'modules/Rates/VortexaRates/VortexaRates';
import WorkflowPage from 'modules/Workflows/WorkflowPage';
import CargoTracker from 'modules/CargoTracker/CargoTrackerPage';
import ArtisCharting from 'modules/ArtisCharting';
import DistListPage from 'modules/DistList/DistListPage';
import ChartWindow from 'modules/ArtisCharting/Components/ChartWindow';
import Rates from 'modules/Rates';
import BlotterPage from 'modules/Blotter';
import ContactSales from 'components/ContactSales';

import { ResultsModeEnum } from 'modules/Sanctions/Models/Enums';
import { CargoTrackerModeEnum } from 'modules/CargoTracker/Models/Enums';

import { RibbonTypes } from 'components/Ribbons/Models/RibbonTypes';
import { SecurityRights } from 'components/OBXUser/Model/SecurityRightsEnum';

import './index.scss';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const configPromise = fetch('/config.json');

export type ConfigParams = {
	apiBase?: string;
	uiAuthDomain?: string;
	uiAuthClientId?: string;
	azureMapsKey?: string;
	signalREndpoint?: string;
	lowerLevelEnvironment?: boolean;
}

export const ConfigContext: React.Context<ConfigParams> = React.createContext({});

export type RatesConfig = {
	accessCode: number;
	component: string;
	label: string;
	path: string;
	subpath: string;
};

export type SanctionsConfig = {
	label: string;
	path: string;
	subpath: string;
	resultsMode: ResultsModeEnum;
};

export type CargoTrackerConfig = {
	label: string;
	path: string;
	resultsMode: CargoTrackerModeEnum;
}

export const RATES_ROUTE_CONFIG: RatesConfig[] = [
	{ path: 'baltic-spot', label: 'Baltic', subpath: ':market', component: 'BalticRates', accessCode: SecurityRights.BalticRates },
	{ path: 'vortexa', label: 'Vortexa', subpath: ':market', component: 'VortexaRates', accessCode: SecurityRights.VortexaRates },
	{ path: 'iea-mods', label: 'IEA', subpath: ':source', component: 'IEAModsData', accessCode: SecurityRights.IEARates },
	{ path: 'jodi-mods', label: 'JODI', subpath: ':source', component: 'JodiModsData', accessCode: SecurityRights.JodiRates },
	{ path: 'rate-grids', label: 'Rate Grids', subpath: ':grid', component: 'RateGrids', accessCode: SecurityRights.RateGrids },
];

const SANCTIONS_ROUTE_CONFIG: SanctionsConfig[] = [
	{ path: 'vessel', label: 'Vessel Sanctions', subpath: ':search', resultsMode: ResultsModeEnum.VesselSanctions },
	{ path: 'entity', label: 'Entity Sanctions', subpath: ':search', resultsMode: ResultsModeEnum.LegalEntitySanctions },
	{ path: 'individual', label: 'Individual Sanctions', subpath: ':search', resultsMode: ResultsModeEnum.IndividualSanctions },
];

const TESTING_ROUTE_CONFIG = [
	{ path: 'user-settings', label: 'User Settings', element: <UserSettingsPlayground /> }
];

const CARGO_TRACKER_ROUTE_CONFIG: CargoTrackerConfig[] = [
	{ path: 'spot', label: 'Spot', resultsMode: CargoTrackerModeEnum.Spot },
	{ path: 'time-charter', label: 'TC', resultsMode: CargoTrackerModeEnum.TC },
]

// Redirect old URL "surveillance/:worksheet" to new "surveillance/results/:worksheet"
const SurveillanceRedirect = ():JSX.Element => {
  const params = useParams();
  if (params.batchparam) {
    return <Navigate to={`/surveillance/results${ params.worksheetparam ? `/${ params.worksheetparam }/batch/${ params.batchparam }` : '' }`} replace />;
  } else {
    return <Navigate to={`/surveillance/results${ params.worksheetparam ? `/${ params.worksheetparam }` : '' }`} replace />;
  }
};

const SurveillanceLoader = () => ({
	heading: 'SURVEILLANCE',
	accessCode: SecurityRights.Surveillance,
	ribbons: [RibbonTypes.MobileVersionInDevelopment],
});

configPromise
	.then((res) => res.json())
	.then(config => {

		let win: any = window;
		win.signalREndpoint = config.signalREndpoint;

		document.documentElement.setAttribute("data-user-agent", navigator.userAgent);

		axios.interceptors.request.use(request => {
			request.baseURL = config.apiBase;
			return request;
		});

		config = {...config, lowerLevelEnvironment: config.lowerLevelEnvironment === 'true'}

		const appProps = { config }

		/* NOTE: remember to add `accessCode` inside a loader method.
		You won't be able to access to the page without it. */
		const routes = createBrowserRouter([
			{
				path: "/",
				loader: () => ({
					ribbons: [RibbonTypes.NoInternetConnection],
					accessCode: SecurityRights._Unprotected
				}),
				element: <Auth0Wrapper config={config}><App {...appProps} /></Auth0Wrapper>,
				errorElement: <ErrorPage />,
				children: [
					{ path: "/", element: <Navigate to="/reports" /> },
					{
						path: "/dashboard",
						element: <Reports />,
						errorElement: <ErrorPage /> // add errorElement on the path level so left menu is still available
					},
					{
						path: '/distance-calculator',
						element: <DistanceCalcPage />,
						errorElement: <ErrorPage />,
						loader: () => ({
							accessCode: SecurityRights.DistanceCalculator,
							heading: "Distance Calculator"
						})
					},
					{
						path: "/sanctions",
						loader: () => ({
							accessCode: SecurityRights.Sanctions,
						}),
						errorElement: <ErrorPage />,
						children: SANCTIONS_ROUTE_CONFIG.map((item: any) => {
							let { path, subpath, resultsMode, label } = item;
							return {
								path,
								resultsMode,
								element: <SanctionsPage resultsMode={resultsMode} items={SANCTIONS_ROUTE_CONFIG} />,
								loader: () => ({ heading: label, accessCode: SecurityRights.Sanctions }),
								children: [
									{
										path: subpath,
										loader: () => ({ accessCode: SecurityRights.Sanctions }),
										element: <SanctionsPage resultsMode={resultsMode} items={SANCTIONS_ROUTE_CONFIG} />
									}
								]
							}
						})
					},
					{
						path: '/rates',
						// NOTE: Trick for redirecting to first available sub-module from Rates.
						// On click on the Rates in the LeftMenu we have to check which package (Baltic, Vortexa, etc.) is allowed
						// and redirect to first possible rather than redirect to the SalesPage
						element: <Rates />,
						errorElement: <ErrorPage />,
						loader: () => ({
							accessCode: RATES_ROUTE_CONFIG.map(r => r.accessCode),
							heading: 'Rates'
						}),
						children: RATES_ROUTE_CONFIG.map((item) => {
							const { component, path, subpath, label, accessCode } = item;
							let element: ReactNode;

							switch(component) {
								case 'BalticRates':
									element = <BalticRates items={RATES_ROUTE_CONFIG} />;
									break;
								case 'VortexaRates':
									element = <VortexaRates items={RATES_ROUTE_CONFIG} />;
									break;
								case 'IEAModsData':
									element = <CommoditiesData items={RATES_ROUTE_CONFIG} feed='iea' />;
									break;
								case 'JodiModsData':
									element = <CommoditiesData items={RATES_ROUTE_CONFIG} feed='jodi' />;
									break;
								case 'RateGrids':
								default:
									element = <RateGrids items={RATES_ROUTE_CONFIG} />;
									break
							}

							return {
								path,
								element,
								loader: () => ({ accessCode }),
								children: [{
									path: subpath,
									element,
									loader: () => ({ heading: label, accessCode })
								}],
							}
						})
					},
					{
						path: "/cargo-flows/:worksheetparam?",
						element: <CargoFlows />,
						loader: () => ({
							ribbons: [RibbonTypes.MobileVersionInDevelopment],
							heading: "Cargo Flows",
							accessCode: SecurityRights.CargoMovements
						})
					},
					{
						path: '/cargo-tracker',
						errorElement: <ErrorPage />,
						loader: () => ({ accessCode: SecurityRights.CargoTracker }),
						children: CARGO_TRACKER_ROUTE_CONFIG.map(ct => {
							const { path, resultsMode } = ct;
							const loader = () => ({
								heading: "Cargo Tracker",
								accessCode: SecurityRights.CargoTracker
							});

							return {
								element: <CargoTracker resultsMode={resultsMode} items={CARGO_TRACKER_ROUTE_CONFIG} />,
								loader,
								path,
								resultsMode,
							}
						})
					},
					{
						path: "/secure-mail",
						errorElement: <ErrorPage />,
						children: [
							{
								path: "generate",
								element: <SecureEmailGeneratePage />,

								loader: () => ({ heading: "Secure Mail" }),
							},
							{ path: ":messageId", element: <SecureEmailPage /> }
						]
					},
					{
						path: "/secure-mail-processing",
						children: [
							{ path: ":messageId", element: <SecureEmailGeneratePage />, loader: () => ({ heading: "Secure Mail" }) }
						]
					},
					{
						path: "/reports",
						element: <Reports />,
						loader: () => ({
							ribbons: [RibbonTypes.AlphaRelease],
							heading: "Reports",
							accessCode: SecurityRights.Reports
						}),
						children: [
							{
								path: ":reportId", element: <Reports />,
								loader: () => ({
									ribbons: [RibbonTypes.AlphaRelease],
									heading: "Reports",
									accessCode: SecurityRights.Reports
								}),
								children: [{
									path: ":pageName", element: <Reports />,
									loader: () => ({
										ribbons: [RibbonTypes.AlphaRelease],
										heading: "Reports",
										accessCode: SecurityRights.Reports
									})
								}
								]
							}
						]
					},
					{
						path: "/distlist/:distListIdParam?/:emailIdParam?",
            element: <DistListPage />,
            errorElement: <ErrorPage />,
						loader: () => ({
              accessCode: SecurityRights.DistList,
              heading: 'Distribution list'
            })
					},
					{
						path: '/surveillance',
						errorElement: <ErrorPage />,
						children:
							[
								{ index: true, element: <Navigate to="results" replace />},
								{ path: ':worksheetparam?', element: <SurveillanceRedirect />},
								{ path: ':worksheetparam?/batch/:batchparam?', element: <SurveillanceRedirect />},
								{
									path: 'link-expired',
									element: <ReportLinkExpiredPage />,
									loader: () => ({ ...SurveillanceLoader(), ribbons: [] }),
								},
								{ path: 'results/:worksheetparam?',
									element: <Surveillance resultsMode={SurveillanceModeEnum.Results} />,
									loader: SurveillanceLoader,
								},
								{ path: 'reviewed/:worksheetparam?',
									element: <Surveillance resultsMode={SurveillanceModeEnum.Reviewed} />,
									loader: SurveillanceLoader
								},
								{ path: 'results/:worksheetparam?/batch/:batchparam?',
									element: <Surveillance resultsMode={SurveillanceModeEnum.Results} />,
									loader: SurveillanceLoader,
								},
							]
					},
					{
						path: "/internal",
						element: <InternalTesting items={TESTING_ROUTE_CONFIG} />,
						children: TESTING_ROUTE_CONFIG.map(item => {
							const { path, element } = item;
							return {
								path,
								element,
								loader: () => ({
									accessCode: SecurityRights._Unprotected,
									heading: 'Internal',
								}),
							};
						})
					},
					{
						path: "/workflows",
						errorElement: <ErrorPage />,
						element: <WorkflowPage />,
						loader: () => ({
							heading: 'Workflows',
							accessCode: SecurityRights.Onboarding
						}),
						children: [
							{
								path: ":providerId/:statusFilter", element: <WorkflowPage />,
								loader: () => ({
									heading: 'Workflows',
									accessCode: SecurityRights.Onboarding
								})
							}
						]
					}, {
						// NOTE: re-think how to pass the arguments when the list gets bigger
						path: '/curves/:artispackage/:product/:tenorCode/:tenorIndex/:frequency/:period',
						errorElement: <ErrorPage />,
						element: <ChartWindow />,
						loader: () => ({
							heading: "Curves",
							hideMenu: true,
							accessCode: SecurityRights.Curves
						}),
					},
					{
						path: '/curves/',
						errorElement: <ErrorPage />,
						element: <ArtisCharting />,
						loader: () => ({
							heading: 'Curves',
							accessCode: SecurityRights.Curves
						}),
						children: [{
							path: ':artispackage',
							element: <ArtisCharting />,
							loader: () => ({
								heading: 'Curves',
								accessCode: SecurityRights.Curves
							}),
						}]
					},
					{
						path: '/blotter',
						errorElement: <ErrorPage />,
						element: <BlotterPage />,
						loader: () => ({
							heading: 'Blotter',
							accessCode: SecurityRights.Blotter
						}),
					},
					{
						path: '/contact-sales/:moduleName?',
						errorElement: <ErrorPage />,
						element: <ContactSales />,
						loader: () => ({
							// heading: 'Contact Sales',
							accessCode: SecurityRights._Unprotected
						}),
					},
				]
			},
		]);

		root.render(
			<React.StrictMode>
				<ConfigContext.Provider value={config}>
					<RouterProvider router={routes} />
				</ConfigContext.Provider>
			</React.StrictMode>
		);


	});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

