import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';

import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';

import { SurveillanceEntityStatus } from '../../Models/Enums';
import { ResultsResponse } from '../../Models/ReportsResponse';
import { StateRequest, useUpdateAuditState } from '../../Services/AuditService';

import { escalateValidator } from './Models/Validators';

import { formatName } from 'helpers/Utils/string';

import type { SuggestionResponse } from '../../../CargoTracker/Components/GroupedSearch';

import styles from './EscalatePopup.module.scss';

export interface EscalatePopupReferenceProps {
  submit: () => Promise<StateRequest | undefined>;
}

export interface EscalatePopupProps {
  activeWorksheetId: string;
  selectedItems: ResultsResponse[];
}

const EscalatePopup = forwardRef<EscalatePopupReferenceProps, EscalatePopupProps>(
  ({
    activeWorksheetId,
    selectedItems,
  }, ref): JSX.Element => {
    const [ assignedUserId, setAssignedUserId] = useState<string | null>(null);
    const [ notes, setNotes] = useState<string>();
    const [ isValid, setIsValid ] = useState<boolean>(false);
    const [ isValidationVisible, setIsValidationVisible ] = useState<boolean>(false);
    const { trigger: triggerUpdate } = useUpdateAuditState(activeWorksheetId);

    useImperativeHandle(ref, () => ({
      submit: (): Promise<StateRequest | undefined> => {
        if (isValid) {
          return triggerUpdate({
            items: selectedItems.map(el => ({
              id: el.id,
              partitionKey: el.partitionKey,
            })),
            assignedUserId: assignedUserId,
            status: SurveillanceEntityStatus.Escalated,
            note: notes || '',
            worksheetId: activeWorksheetId || '',
          });
        } else {
          setIsValidationVisible(true);
          return Promise.reject('validator');
        }
      },
    }));

    useEffect(() => {
      if (!activeWorksheetId) {
        return;
      }

      const result = escalateValidator.validate(
        { assignedUserId, notes },
        { convert: false }
      );

      setIsValid(!result.error);
    }, [activeWorksheetId, notes, assignedUserId]);

    const personErrorMessage = (): JSX.Element => (
      <small className="reason-invalid">Please select person</small>
    );

    return (
      <form>
        <div className={styles.surveillanceEscalatePopup}>
          <div className={styles.surveillanceEscalatePopupPerson}>
            <SingleEntitySearch
              label='Person*'
              placeholder='Select Person'
              module={EntitySearchGroupEnum.Users}
              fields={EntitySearchFieldsEnum.User}
              itemTemplate={(i: SuggestionResponse):string => formatName(i.value)}
              onInputClear={():void => setAssignedUserId(null)}
              callback={(change?: SuggestionResponse | null):void =>
                setAssignedUserId(change?.searchEntityId ?? null)
              }
            />
            {isValidationVisible && !assignedUserId && personErrorMessage()}
          </div>
          <div className={styles.surveillanceEscalatePopupNote}>
            <label>Note</label>
            <InputTextarea
              id='escalate-notes'
              autoResize
              placeholder='Please add an optional note/comment'
              onFocus={(e): void => e.target.select()}
              rows={5}
              value={notes ?? ''}
              onChange={(e): void => setNotes(e.target.value)}
            />
          </div>
        </div>
      </form>
    );
  }
);

EscalatePopup.displayName = 'EscalatePopup';

export default EscalatePopup;
