import { useEffect, useState, useRef, ReactNode, useMemo, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { clsx } from 'clsx';

import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';
import { EntitySearchGroupEnum, EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
import GroupedEntitySearch from 'components/EntitySearch/GroupedEntitySearch';
import OpenWorksheets from 'components/Worksheets/OpenWorksheets';
import { MutationType, WorksheetSignalMessageEventTypes, WorksheetStores } from 'components/Worksheets/Models/Enums';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { WorksheetMutationPayload, WorksheetMutationTypes, useLoadWorksheet, useMutateWorksheet } from 'components/Worksheets/Services/WorksheetHooks';
import { capFirstLetter, stripParams, uniqueId } from 'helpers/Utils/string';
import eventBus from 'server/EventBus';
import AdditionalFilters from 'components/AdditionalFilters';
import Loader from 'components/Loader';

import SearchTokens from './Components/SearchTokens';
import CargoDataTable, { DATA_CONFIG } from './Components/CargoDataTable';
import ProbabilityLevelFilter from './Components/ProbabilityLevelFilter';
import STSFilter from './Components/STSFilter';

import { BasicTextWithFlag } from './Templates/BasicTextWithFlag';
import { HistoricalFlowsApi } from './Services/HistoricalFlows';

import { CargoMovementOrderCriteriaEnum, type ConditionalFilter, type ConditionalFilterChange } from './Models/CargoMovements';
import type { WorksheetMetaProps, WorksheetSearchField } from 'components/Worksheets/Models/WorksheetResponse';

import styles from './CargoFlows.module.scss';
import { ServerSortHeader } from 'helpers/DataTable/Templates/Headers';

function additionalSearchPropParser (props: WorksheetMetaProps[] = []): WorksheetMetaProps[] {
  return props.map(p => {
    try {
      return { ...p, value: JSON.parse(p.value as string) }
    } catch(e) {
      return p;
    }
  });
}

function fieldsParser (fields: WorksheetSearchField[] = []): WorksheetSearchField[] {
	return fields.map(f => {
    const { metaData, searchField, ...rest } = f;
    const props = metaData?.reduce(
      (acc, c) => ({...acc, [c.key as string]: c.value}),
      {} as WorksheetSearchField
    );

    return {
      ...rest,
      ...props,
      searchField: +searchField,
    }
  })
}

// just a function that return empty array for now, but might be usefull in the feature...
export function hydrator (): WorksheetMetaProps[] {
  return [];
}

export interface ISearchFacet {
  searchTerm: string;
  searchField: EntitySearchFieldsEnum,
  context?: string;
  key?: string;
}

function CargoFlows() {

	const currentRoute = useLocation();
	const { worksheetparam } = useParams();
  const [ searches, setSearches ] = useState<ISearchFacet[]>([]);
  const [ conditionalFilters, setConditionalFilters ] = useState<ConditionalFilter[]>([]);
  const [ conditionalFiltersToken, setConditionalFiltersToken ] = useState<ISearchFacet[]>([]);
  const [ expandState, setExpandState ] = useState<boolean>(false);
  const [ exportable, setExportable] = useState<boolean>(false);
  const [ downloading, setDownloading ] = useState<boolean>(false);
  const [ worksheetid, setWorksheetid ] = useState<string | undefined>(worksheetparam);
  const dataTable = useRef();
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
	const [ orderByColumns, setOrderByColumns] = useState<string[]>(['StartUtc desc']);
  const [ worksheetLoaded, setWorksheetLoaded ] = useState<boolean>(false);

  useEffect(() => {
    if (!worksheetparam) return;
    
    setWorksheetLoaded(false);
    setWorksheetid(worksheetparam);
  }, [worksheetparam]);

  const { data: worksheet, error, isLoading } = useLoadWorksheet(
		WorksheetStores.CargoFlows,
		worksheetid,
		{
      propsParser: additionalSearchPropParser,
			fieldsParser: fieldsParser,
			hydrator: hydrator,
		}
	);

	const { mutateWorksheet, mutateAdditionalProps, mutateTokens, isMutating } = useMutateWorksheet(
		WorksheetStores.CargoFlows,
		worksheetid
	);

	useEffect(() => {
		if (!worksheet || worksheet.store !== WorksheetStores.CargoFlows) return;

    setWorksheetid(worksheet.worksheetId);

    if (worksheet.fields) {
      setSearches(fieldsParser(worksheet.fields));
    }

    if (worksheet.additionalSearchProperties) {
      const additionalProps = additionalSearchPropParser(worksheet.additionalSearchProperties);
      const conditionalFilters = (additionalProps.find(item => item.key === 'conditionalFilters')?.value ?? []) as ConditionalFilter[];
      const conditionalFiltersToken = (additionalProps.find(item => item.key === 'conditionalFiltersToken')?.value ?? []) as ISearchFacet[];

      setConditionalFilters(conditionalFilters);
      setConditionalFiltersToken(conditionalFiltersToken);
    }

    setWorksheetLoaded(true);
	}, [worksheet, mutateWorksheet]);

	useEffect(() => {
		if (!worksheetLoaded || !worksheet || worksheet.store !== WorksheetStores.CargoFlows) {
      return;
    }

		//	Worksheet has loaded. So replace the search criteria…
		mutateWorksheet({type: WorksheetMutationTypes.Full, payload: worksheet})

		//	let anything else that might need to know the sheet is loaded
		eventBus.dispatch(
			WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
			worksheet
		);
	}, [worksheetLoaded, worksheet, mutateWorksheet]);

  useEffect(() => {
    if (!searches.length) setExpandState(true);
  }, [searches.length]);


  const onSearchItemSelected = (e: {value: string, searchField: EntitySearchFieldsEnum}) => {

    //  Add the search critera…
    const { value, searchField } = e;

    if (!searches.length) setExpandState(true);

    if (searches.some((facet: any) => facet.searchTerm === value && facet.searchField === searchField)) {
      return;
    }

    setSearches(
      (current: ISearchFacet[]) => {
        if (!current) current = [];

        const key: string = uniqueId();
        const newSearches = [...current, { searchTerm: value, searchField, key }];

        mutateTokens(newSearches as WorksheetMutationPayload);

        return newSearches;
    });
  }

  const onSearchItemRemoved = (passedKey: string) => {
    setSearches(
      (current: ISearchFacet[]) => {
        const index: number = searches.findIndex(({key}) => key === passedKey);
        const newSearches = removeItemAt(current, index);

        mutateTokens(newSearches as WorksheetMutationPayload);

        return newSearches;
      }
    )
  }

  const onFilterChange = (change: ConditionalFilterChange, groupName: string): void => {
    const otherFilters = conditionalFilters.filter(item => item.group !== groupName)
    const newFilters = [...otherFilters, ...change.filters];

    const otherToken = conditionalFiltersToken.filter(item => item.key !== groupName);
    const newTokens = [...otherToken, ...change.tokens];

    setConditionalFilters(newFilters);
    setConditionalFiltersToken(newTokens);

    mutateAdditionalProps([
      { key: 'conditionalFilters', value: JSON.stringify(newFilters) },
      { key: 'conditionalFiltersToken', value: JSON.stringify(newTokens) },
    ]);
  }

  const findOrderCriteria = (sortField: string): string => {
    let [ column, ] = sortField.split(",");
    column = capFirstLetter(column);
    column = CargoMovementOrderCriteriaEnum[column as keyof typeof CargoMovementOrderCriteriaEnum];

    return column;
  }

  const handleOrderChange = useCallback((sortField: string) => {
		//  What column are we sorting on? (assumes first item when
		//  multiple columns are passed).
		const column = findOrderCriteria(sortField);

		setOrderByColumns((current) => {

      let orderCol = { item: '', index: -1 };

      const exists = current.some((item, index) => {
        orderCol = { item, index };
        return item.indexOf(column) !== -1;
      });

      //  This column doesn't exist in our ordering - so add it
      if (!exists) return [...current, `${column} asc`];

      switch(orderCol.item.split(' ').at(-1)) {
        case 'asc':
          //  Switch to descending
          return replaceItemAt<string>(current, `${column} desc`, orderCol.index);
        case 'desc':
          //  …or remove completely
          return removeItemAt<string>(current, orderCol.index);
        default:
          return current;
      }
    });
  }, []);

  useEffect(() => {
    setExportable(false);
  }, [searches]);

  const renderError = (): ReactNode => {
    if (error?.response?.status === 500) {

			//	dispatch an error event
			eventBus.dispatch(
				WorksheetSignalMessageEventTypes.WORKSHEET_DENIED,
				{ mutation: MutationType.Error, worksheetId: worksheetid },
				250
			)

			return <div className={styles.worksheetError}>Sorry. This is not a worksheet you are allowed to access</div>
		}
  }

  if (isLoading || isMutating) {
    // handle...
  }

  const exportButton = useMemo(() => <Button
    size="small"
    icon="iconoir-download icon--tiny icon--ob-orange"
    disabled={!exportable}
    loading={downloading}
    className={styles.exportButton}
    outlined
    onClick={
      (e) => {
        // @ts-ignore // TODO: Fix Typescript
				const { queryParams, totalResults } = dataTable.current.getParams();
        HistoricalFlowsApi.exportSearch(queryParams, totalResults,setDownloading);
    }}>
    {isMobile ? 'Export Results' : 'Export' }
  </Button>, [downloading, exportable, isMobile]);

  const tokens = useMemo(() => <div className={clsx("grow-to-fill", styles.upper)}>
      {isLoading ? <Loader /> : <>
        <SearchTokens
          onAdditionalFacetRemove={(id: string) => {
            onFilterChange({ filters: [], tokens: [] }, id);
          }}
          additionalFacets={conditionalFiltersToken}
          facets={searches}
          removeCallBack={onSearchItemRemoved}
          expandState={expandState}
        />
        <Button
          size="small"
          disabled={!searches.length}
          onClick={() => {
            setSearches([]);
            setConditionalFilters([]);
            mutateAdditionalProps([]);
            mutateTokens([]);
          }}
          text
          >
          Clear
        </Button>
        {!isMobile && exportButton}
      </>}
    </div>,
    // eslint-disable-next-line
    [conditionalFiltersToken, expandState, exportButton, isLoading, isMobile, searches]
  );

  const inputs = useMemo(() => {
    const commonProps = {
      chunkSize: 10,
      module: EntitySearchGroupEnum.CargoFlows,
      callback: onSearchItemSelected,
      group: true
    };

    return <>
      <GroupedEntitySearch
        label="label"
        field="value"
        fields={[EntitySearchFieldsEnum.Product, EntitySearchFieldsEnum.ProductCategory, EntitySearchFieldsEnum.ProductGrade, EntitySearchFieldsEnum.ProductGroup]}
        placeholder="Search commodity by type, grade or classification"
        icon="gas-tank-droplet"
        color="egg-plant"
        {...commonProps }
      />
      <GroupedEntitySearch
        label="label"
        fields={[EntitySearchFieldsEnum.LoadPort, EntitySearchFieldsEnum.LoadCountry, EntitySearchFieldsEnum.LoadRegion]}
        placeholder="Search loading activity by country, port or terminal"
        icon="arrow-down-circle"
        color="grass"
        itemTemplate={BasicTextWithFlag}
        {...commonProps }
      />
      <GroupedEntitySearch
        label="label"
        fields={[EntitySearchFieldsEnum.DischargePort, EntitySearchFieldsEnum.DischargeCountry, EntitySearchFieldsEnum.DischargRegion]}
        placeholder="Search discharge activity by country, port or terminal"
        icon="arrow-up-circle"
        color="amber"
        itemTemplate={BasicTextWithFlag}
        {...commonProps }
      />
      <GroupedEntitySearch
        label="label"
        fields={[EntitySearchFieldsEnum.Vessel, EntitySearchFieldsEnum.VesselSize, EntitySearchFieldsEnum.ImoNumber]}
        placeholder="Search activity by vessel name, IMO number class"
        icon="ship"
        color="crimson"
        itemTemplate={BasicTextWithFlag}
        {...commonProps }
      />
      <GroupedEntitySearch
        label="label"
        fields={[EntitySearchFieldsEnum.Charterer, EntitySearchFieldsEnum.Owner]}
        placeholder="Search by company name…"
        icon="city"
        color="slate"
        field="value"
        {...commonProps }
      />
    </> },
    // eslint-disable-next-line
    []);

  const additionalFilters = useMemo(() => (
    <AdditionalFilters isMobileMode={isMobile}>
      <STSFilter activeFilters={conditionalFilters} onChange={onFilterChange} />
      <ProbabilityLevelFilter activeFilters={conditionalFilters} onChange={onFilterChange} />
    </AdditionalFilters>),
  // eslint-disable-next-line
  [conditionalFilters]
  );

  const sortOrder = useMemo(() => {
    const orders = [DATA_CONFIG.vessel, DATA_CONFIG.owner, DATA_CONFIG.charterer, DATA_CONFIG.category, DATA_CONFIG.quantity, DATA_CONFIG.loadPort, DATA_CONFIG.loadTime, DATA_CONFIG.dischargePort, DATA_CONFIG.dischargeTime];

    return orders.map(({ field, label, labelMobile }) => {
      const column = findOrderCriteria(field);

      return <div className={styles.sortingItem} key={field} onClick={() => handleOrderChange(field)}>
        <ServerSortHeader
          by={column}
          current={orderByColumns}
          label={labelMobile ?? label}
        />
      </div>
    });
  }, [handleOrderChange, orderByColumns])

  const searchItems = { tokens, inputs, additionalFilters, sortOrder };

  const renderSearchItems = (): ReactNode => {
    if (isMobile) {
      return <Accordion className={styles.accordion}>
        <AccordionTab className={styles.accordionTab} headerClassName={styles.accordionHeader} header='Search'>
          <Accordion multiple className={clsx(styles.accordion, styles.accordionInner)}>
            <AccordionTab contentClassName={styles.accordionContent} className={styles.accordionTab} header='Current Search'>
              {searchItems.tokens}
            </AccordionTab>
            <AccordionTab contentClassName={styles.accordionContent} className={styles.accordionTab} header='Inputs'>
              <div className={clsx("grow-to-fill", styles.inputs)}>
                {searchItems.inputs}
              </div>
            </AccordionTab>
            <AccordionTab contentClassName={styles.accordionContent} className={styles.accordionTab} header='Additional Filters'>
              {searchItems.additionalFilters}
            </AccordionTab>
            <AccordionTab contentClassName={clsx(styles.accordionContent, 'direction--column')} className={styles.accordionTab} header='Sort Order'>
              {searchItems.sortOrder}
            </AccordionTab>
          </Accordion>
        </AccordionTab>
      </Accordion>
    }

    return <>
      {searchItems.tokens}
      <div className={clsx("grow-to-fill", styles.inputs)}>
        {searchItems.inputs}
        {searchItems.additionalFilters}
      </div>
    </>
  }

  return <>
    <OpenWorksheets
			store={WorksheetStores.CargoFlows}
      uiSettingName={UISettings.CARGO_FLOWS_WORKSHEETS}
			worksheetid={worksheetid}
			setWorksheetid={setWorksheetid}
			currentRoute={stripParams(currentRoute.pathname, worksheetparam)}
			hydrator={hydrator}
		/>
    {worksheet ? <>
    <header className={styles.header}>
      <div className={clsx("direction--column grow-to-fill", styles.headerItems)}>
        { renderSearchItems() }
      </div>
    </header>
    {isMobile && exportButton}
    <main
      className={clsx(
        "grow-to-fill",
        styles.main
      )}>
        <section className="overflow--hidden grow-to-fill">
          <CargoDataTable
            conditionalFilters={conditionalFilters}
            handleOrderChange={handleOrderChange}
            orderByColumns={orderByColumns}
            ref={dataTable}
            searches={searches}
            setIsExportable={setExportable}
            worksheetId={worksheetLoaded ? worksheetid : undefined}
          />
        </section>
    </main>
    </>
    : renderError() }
  </>
}

export default CargoFlows;
