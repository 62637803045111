import {
  FormEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

import AudioPlayer from 'components/AudioPlayer';
import NotAvailable from 'components/NotAvailable';

import {
  AttachmentComponentProps,
  AttachmentType,
  AttachmentTypeMap,
  DetailsVersion,
  MIMEType,
  TranscriptionComponentProps,
  TranscriptionState,
} from '../../Models';
import {
  useFile,
  useStoreRecordingPlayedToEndFlag,
} from '../../Services/SurveillanceDetailsAPI';

import AttachmentsSection from './Components/AttachmentsSection';
import MarkTextComponent from './Components/MarkTextComponent';
import TranscriptionsFooter from './Components/TranscriptionFooter';

import { uniqueId } from 'helpers/Utils/string';
import {
  SurveillanceMediaRecording,
  SurveillanceMediaText,
} from 'modules/Surveillance/Models/ReportsResponse';

import styles from './TranscriptionComponent.module.scss';

const DATE_FORMAT = 'dd LLL HH:mm:ss';

const audioTypes = Object.keys(SurveillanceMediaRecording);
const noRecordMediaTypes = [
  ...Object.keys(SurveillanceMediaText),
  SurveillanceMediaRecording.WhatsAppAudio,
];

const isTranscriptionsEqual = (
  a?: DetailsVersion,
  b?: DetailsVersion
): boolean => a?.updatedAt === b?.updatedAt;

const TranscriptionComponent = ({
  message,
  worksheetId,
  isMain = false,
  isMessageSelected = false,
  className,
  onTranscriptionStateChanged,
  saveTranscriptionVersion,
  onSelectionChanged,
}: TranscriptionComponentProps): JSX.Element | null => {
  const [transcriptionState, setTranscriptionState] = useState(
    TranscriptionState.View
  );
  const [selectedVersion, setSelectedVersion] = useState<
    DetailsVersion | undefined
  >(message.versions?.[0]);
  const [shouldRequestAudio, setShouldRequestAudio] = useState(false);
  const [isEditingDisabled, setIsEditingDisabled] = useState(false);
  const [editTranscriptionText, setEditTranscriptionText] = useState<
    string | undefined
  >(selectedVersion?.text);
  const [transcriptionText, setTranscriptionText] = useState<
    string | undefined
  >(selectedVersion?.text);

  const isAudioMessage = message.media && audioTypes.includes(message.media);
  const isTextOnly =
    message.media && noRecordMediaTypes.includes(message.media);

  const contentRef = useRef<HTMLTextAreaElement>(null);

  const isCurrent = useCallback(
    (selected?: DetailsVersion): boolean =>
      selected?.updatedAt === message.versions?.[0]?.updatedAt,
    [message.versions]
  );

  useEffect(
    () => setSelectedVersion(message.versions?.[0]),
    [message.versions]
  );

  useEffect(() => {
    if (isCurrent(selectedVersion)) {
      setTranscriptionText(
        message.highlights?.content?.[0] || selectedVersion?.text
      );
    } else {
      setTranscriptionText(selectedVersion?.text);
    }
    setEditTranscriptionText(selectedVersion?.text);
  }, [selectedVersion, message, isCurrent]);

  useEffect(() => {
    setIsEditingDisabled(
      !isTranscriptionsEqual(selectedVersion, message.versions?.[0]) ||
        !transcriptionText?.trim()
    );
  }, [message.versions, selectedVersion, transcriptionText]);

  /**
   * reset state when message or selected version has been changed
   */
  useEffect(() => {
    setShouldRequestAudio(false);
    if (selectedVersion) {
      setTranscriptionState(TranscriptionState.View);
      onTranscriptionStateChanged &&
        onTranscriptionStateChanged(message, TranscriptionState.View);
    }
  }, [message, selectedVersion, onTranscriptionStateChanged]);

  const { url: dataSource, mime } = useFile(
    message && ((isAudioMessage && shouldRequestAudio) || message.isAttachment)
      ? {
        id: message.id,
        userName: message.userName,
        companyName: message.company,
        providerName: message.provider,
        startTime: message.startTime.toString(),
      }
      : null,
    worksheetId
  );

  const mimeType: MIMEType | undefined = mime?.split('/')[0] as MIMEType;
  const attachment: AttachmentComponentProps = {
    type: AttachmentTypeMap[mimeType] || AttachmentType.File,
    source: dataSource || '',
    fileName: message.id,
  };

  const { trigger: storeRecordingPlayedToEndFlag } =
    useStoreRecordingPlayedToEndFlag();

  const versionToOption = (item?: DetailsVersion): string | undefined =>
    item?.isOriginal
      ? 'Original version'
      : isTranscriptionsEqual(item, message.versions?.[0])
        ? 'Current version'
        : item?.updatedAt &&
        `${ DateTime.fromISO(item?.updatedAt).toFormat(DATE_FORMAT) } - ${
          item?.updatedByName
        }`;

  const onSave = (): void => {
    saveTranscriptionVersion &&
      editTranscriptionText &&
      saveTranscriptionVersion(editTranscriptionText).then(() => {
        setTranscriptionState(TranscriptionState.View);
        onTranscriptionStateChanged &&
          onTranscriptionStateChanged(message, TranscriptionState.View);
      });
  };
  const onTranscriptionChanged = (e: FormEvent<HTMLTextAreaElement>): void => {
    setEditTranscriptionText(e.currentTarget.value);
  };
  const onTranscriptionKey = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key.toLowerCase() === 'escape') {
      onCancel();
    }
  };
  const onEdit = (): void => {
    setTranscriptionState(TranscriptionState.Edit);
    onTranscriptionStateChanged &&
      onTranscriptionStateChanged(message, TranscriptionState.Edit);
  };
  const onCancel = (): void => {
    setTranscriptionState(TranscriptionState.View);
    onTranscriptionStateChanged &&
      onTranscriptionStateChanged(message, TranscriptionState.View);
    if (isCurrent(selectedVersion)) {
      setTranscriptionText(
        message.highlights?.content?.[0] || selectedVersion?.text
      );
    } else {
      setTranscriptionText(selectedVersion?.text);
    }
  };

  const onPlay = useCallback(() => {
    if (!dataSource) {
      setShouldRequestAudio(true);
    }
  }, [dataSource]);
  const onPlayed = useCallback(() => {
    if (message.provider) {
      storeRecordingPlayedToEndFlag({
        id: message.id,
        partitionKey: message.partitionKey,
        providerName: message.provider,
      });
    }
  }, [
    message.id,
    message.partitionKey,
    message.provider,
    storeRecordingPlayedToEndFlag,
  ]);

  const onVersionChanged = useCallback(
    (event: DropdownChangeEvent): void => {
      setSelectedVersion(event.value);
      onTranscriptionStateChanged &&
        onTranscriptionStateChanged(message, TranscriptionState.View);
    },
    [message, onTranscriptionStateChanged]
  );

  return (
    <div
      id={`${ message.id }-${ message.partitionKey }`}
      className={clsx(className, 'grow-to-fill')}
    >
      <div className={clsx(styles.border, 'direction--column grow-to-fill')}>
        {isMain && <div className={styles.mainHeader}>Main message</div>}
        <div className={clsx(styles.export, { hidden: isMain })}>
          <Checkbox
            inputId={`select-${ message.id }-${ message.partitionKey }`}
            checked={isMessageSelected}
            onChange={({ checked }: CheckboxChangeEvent): void => {
              onSelectionChanged && onSelectionChanged(Boolean(checked));
            }}
          />
          <label htmlFor={`select-${ message.id }-${ message.partitionKey }`}>
            Include in Export
          </label>
        </div>
        <div className={styles.container}>
          {message.startTime && (
            <time>
              {DateTime.fromISO(message.startTime.toString())
                .setZone('UTC')
                .toFormat('dd LLL yyyy, HH:mm:ss')}
              {' UTC'}
            </time>
          )}
          {message.isAttachment && (
            <div>
              <AttachmentsSection header='Attachment' data={attachment} />
            </div>
          )}
          {!isTextOnly && ( // For VoxSmart SMS there is no recording
            <div>
              <label>Record</label>
              <AudioPlayer
                source={dataSource}
                onPlay={onPlay}
                onEnd={onPlayed}
              />
            </div>
          )}
          <label>{isTextOnly ? 'Message' : 'Transcription'}</label>
          <div className={styles.versions}>
            {message.versions?.length === 1 ? (
              <InputText disabled value='Original version' />
            ) : (
              <Dropdown
                options={message.versions}
                optionLabel='text'
                valueTemplate={(item): JSX.Element => (
                  <div>{versionToOption(item)}</div>
                )}
                value={selectedVersion}
                disabled={message.versions?.length === 1}
                onChange={onVersionChanged}
                itemTemplate={versionToOption}
              />
            )}
            {isMain && message.isEditable && !isEditingDisabled && (
              <Button
                className={clsx(
                  styles.surveillanceTranscriptionEdit,
                  transcriptionState === TranscriptionState.Edit ? 'hidden' : ''
                )}
                icon='iconoir-edit-pencil icon--tiny icon--ob-orange'
                disabled={isEditingDisabled}
                onClick={onEdit}
                label='Edit'
                text
              />
            )}
          </div>
          <div className={clsx(styles.content)}>
            <div className={clsx(styles.message, 'grow-to-fill')}>
              {selectedVersion?.text ? (
                <>
                  <textarea
                    ref={contentRef}
                    hidden={transcriptionState === TranscriptionState.View}
                    value={editTranscriptionText}
                    onChange={onTranscriptionChanged}
                    onKeyUp={onTranscriptionKey}
                  />
                  <div
                    className={clsx(
                      styles.highlightsSection,
                      transcriptionState === TranscriptionState.Edit
                        ? 'hidden'
                        : 'direction--column'
                    )}
                  >
                    {transcriptionText?.split('\n').map(message => (
                      <div key={uniqueId()}>
                        <MarkTextComponent>{message}</MarkTextComponent>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <NotAvailable label='Empty' />
              )}
            </div>
          </div>
        </div>
        <TranscriptionsFooter
          isDisabled={isEditingDisabled}
          isEditing={transcriptionState === TranscriptionState.Edit}
          onCancel={onCancel}
          transcription={selectedVersion}
          onSave={onSave}
        />
      </div>
    </div>
  );
};

export { TranscriptionComponent };
export default TranscriptionComponent;
