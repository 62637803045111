import { getValueCollection } from 'helpers/Utils/enum';
import { TradeType } from './Enums';

import type { SearchRequest } from './SearchRequest';

export const TRADES_DATA_KEY = 'blotter-trades-data';

export const PRICE_UNITS = ['$', 'WS'];

export const QUANTITY_UNITS = ['KT/month', 'KT/week'];

export const TRADE_TYPES = getValueCollection(TradeType, false).map(i => i.key);

export const EMPTY_SEARCH_PARAMS: SearchRequest = {
  searchRequestFields: [],
  dateTime: null,
  prices: null,
  quantities: null,
  orderByFields: []
};

export const BLOTTER_PAGE_SIZE = 1000;

export const MOBILE_GRID_ROW_HEIGHT = 260; // px

export const MOBILE_HEADER_HEIGHT = 221; // px