export enum EntitySearchGroupEnum {
  CargoFlows = 0,
  DistanceCalculator = 1,
  PositionList = 2,
  Users = 3,
	CargoTracker = 4,
  Products = 5,
  Surveillance = 6,
  Blotter = 7
}

export enum EntitySearchFieldsEnum {
  LoadPort = 0,
  DischargePort = 1,
  VesselSize = 2,
  Product = 3,
  Vessel = 4,
  Charterer = 5,
  Owner = 6,
  LoadRegion = 7,
  DischargRegion = 8,
  LoadCountry = 9,
  DischargeCountry = 10,
  ImoNumber = 11,
  ProductCategory = 12,
  ProductGrade = 13,
  ProductGroup = 14,
  Port = 15,
  Country = 16,
  ControlledBy = 18,
  CDB = 19,
	VesselType = 20,
	VesselSubType = 21,
	VesselStatus = 22,
  User = 23,
  Zone = 24,
  CargoCode = 25,
  SurveillanceUserName = 26,
  SurveillanceCounterParty = 27,
  SurveillanceProvider = 28,
  SurveillanceKeyword = 29,
  SurveillanceCompany = 30,
  SurveillanceMedia = 31,
  SurveillanceRawSearch = 32,
  SurveillanceSearchKey = 33,
  Instrument = 34,
  BuyerCompany = 35,
  BuyerContactName = 36,
  BuyerTradingAccount = 37,
  SellerCompany = 38,
  SellerContactName = 39,
  SellerTradingAccount = 40,
  SurveillanceReportState = 41,
  TradeCompany = 42,
  SecuredResourceClientName = 43,
  SecuredResourceClientEmail = 44,
  OBBroker = 45
}
