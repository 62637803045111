import { type ChangeEvent, type FocusEvent, useMemo, useState } from 'react';
import { Checkbox, type CheckboxChangeEvent } from 'primereact/checkbox';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { DateTime } from 'luxon';
import clsx from 'clsx';

import SwitchableUnitField from 'components/SwitchableUnitField';
import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import DateTimeRange from 'components/DateTimeRange';
import TextInput from 'components/TextInput';
import { getAutocompleteValue } from 'components/Autocomplete/Helpers';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { humanDate } from 'helpers/Utils/formatters';
import { QUANTITY_UNITS, TRADE_TYPES } from 'modules/Blotter/Models/Consts';
import { getFixedValue } from 'modules/Blotter/Helpers';

import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import type { SectionProps } from 'modules/Blotter/Models/SectionProps';
import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';
import type { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';

interface MainSectionProps extends SectionProps {
  originalData: TradesDataResponse | null;
  setIsDateParsing: (arg: boolean) => void;
};

export function MainSection(props: MainSectionProps): JSX.Element {
  const { mutate, request, errors, shouldShowError, originalData, setIsDateParsing } = props;
  const [showDateTimeError, setShowDateTimeError] = useState<boolean>(false);

  const quantityOptions = useMemo(() => {
    if (!originalData || QUANTITY_UNITS.find(u => u === originalData.quantity.unit)) {
      return QUANTITY_UNITS;
    }

    // add original unit as an option
    return [...QUANTITY_UNITS, originalData.quantity.unit];
  }, [originalData]);

  return <section>
    <div className='section--main'>
      <div className='section--main__row-1'>
        <SingleEntitySearch
          allowCustomSelection
          callback={(change?: SearchSuggestionsResponse | string): void => mutate({ instrument: getAutocompleteValue(change) })}
          onInputClear={(): void => mutate({ instrument: undefined })}
          label='Instrument*'
          chunkSize={10}
          errorVisibleAfterTouch={false}
          showError={shouldShowError('instrument')}
          fields={EntitySearchFieldsEnum.Instrument}
          module={EntitySearchGroupEnum.Blotter}
          initialTerm={request.instrument}
          itemTemplate={(i: SearchSuggestionsResponse): string => i.value}
          onFocusMethod={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
          placeholder='Search or create Instrument'
        />
        <div className="form-input__container">
          <label htmlFor='trade__type'>Type</label>
          <div className={clsx('p-inputgroup', { 'p-invalid': shouldShowError('type') })}>
            <Dropdown
              id='trade__type'
              value={request.type}
              onChange={(e: DropdownChangeEvent): void => mutate({ type: e.value }, 'type')}
              options={TRADE_TYPES}
              optionDisabled={opt => opt === 'Spread'} // TODO: for now, until 'Spread' type is implemented
            />
          </div>
          {shouldShowError('type') && <small className='message-invalid'>Required field</small>}
        </div>
        <div className='form-input__container direction--row trade-input--single-line'>
          <Checkbox
            checked={request.nextDayPriced}
            value={request.nextDayPriced}
            inputId={`trade__next-day-priced-checkbox`}
            onChange={(e: CheckboxChangeEvent): void => mutate({ nextDayPriced: e.checked }, 'nextDayPriced')}
          />
          <label htmlFor='trade__next-day-priced-checkbox'>Next Day Priced</label>
        </div>
      </div>
      <div className='section--main__row-2'>
        <div className='form-input__container'>
          <label htmlFor='trade__clearing-id'>Clearing ID*</label>
          <TextInput
            id='trade__clearing-id'
            defaultValue={request.clearing.id ?? ''}
            onChange={(value: string): void => mutate({
              clearing: {
                ...request.clearing,
                id: value,
                cleared: !!(request.clearing.house && value) // update 'cleared' value since it depends on id and house values
              }
            }, 'clearing.id')}
            showError={shouldShowError('clearing.id')}
            placeholder='Enter on ID'

          />
        </div>
        <div className='form-input__container'>
          <label htmlFor='trade__clearing-house'>Clearing House*</label>
          <TextInput
            id='trade__clearing-house'
            defaultValue={request.clearing.house ?? ''}
            onChange={(value: string): void => mutate({
              clearing: {
                ...request.clearing,
                house: value,
                cleared: !!(request.clearing.id && value) // update 'cleared' value since it depends on id and house values
              }
            }, 'clearing.house')}
            showError={shouldShowError('clearing.house')}
            placeholder='Enter House'
          />
        </div>
        <DateTimeRange
          label='Trade Date, Time*'
          key={`trade__trade-datetime-${request.id}`}
          showNowButton
          onEmptyValue={(): void => {
            mutate({ dateTime: null });
            setIsDateParsing(false);
          }}
          onParsingStart={(): void => {
            setIsDateParsing(true);
            setShowDateTimeError(false);
          }}
          onDateParsed={(date: ParssedDateTimeResult): void => {
            mutate({ dateTime: DateTime.fromISO(date.fromString) });
            setIsDateParsing(false);
          }}
          onDateParseError={(value: string) => {
            mutate({ dateTime: value });
            setIsDateParsing(false);
            setShowDateTimeError(true);
          }}
          defaultValue={request.dateTime ? humanDate(request.dateTime.toString(), { time: true, toUTC: true }) : ''}
          showErrorMessage={shouldShowError('dateTime') || showDateTimeError}
          placeholder='Enter Date, Time'
          required
        />
        <SwitchableUnitField
          className='form-input__container'
          inputType='number'
          label='Quantity*'
          inputProps={{
            className: clsx({
              'p-invalid': shouldShowError('quantity.amount')
            }),
            showError: shouldShowError('quantity.amount'),
            keyfilter: 'pnum',
            type: 'number',
            value: `${request.quantity.amount ?? ''}`,
            placeholder: 'Enter Value',
            onChange: (e: ChangeEvent<HTMLInputElement>) => mutate({
              quantity: {
                ...request.quantity,
                amount: getFixedValue(e.target.value)
              }
            }, 'quantity.amount'),
          }}
          dropdownProps={{
            className: clsx({
              'p-invalid': shouldShowError('quantity.unit')
            }),
            showError: shouldShowError('quantity.unit'),
            error: errors?.['quantity.unit'],
            onChange: (e: DropdownChangeEvent) => mutate({ quantity: { ...request.quantity, unit: e.value } }, 'quantity.unit'),
            options: quantityOptions,
            value: request.quantity.unit,
          }}
        />
      </div>
    </div>
  </section>;
}
