import axios from 'axios';
import Joi from 'joi';
import type { AxiosError, AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import useSWR, { MutatorCallback, State, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import {
  DistributionListCreateRequest,
  DistributionListMessagesSearchRequest,
  ResendParams,
} from '../Models/distribution-list-create-request';
import {
  DistributionListMessagesSearchResponse,
  DistributionListResponseSingle,
  MessageRecipients
} from '../Models/distribution-list-response';

import { DistributionList } from '../Models/distribution-list-response';
import { apiDistributionListSummary } from '../Models/apiResponse';
import { apiSearchSecureEmailRequest, apiDistributionListUpdateRequest } from '../Models/apiRequest';

import { replaceItemAt } from 'helpers/Utils/collections';

import { ResponseError } from 'models/shared/error';
import { parsePropsToDateTime } from 'helpers/Utils/misc';

export const DIST_LISTS_CACHE: string = 'DistributionLists';

const searchValidator = Joi.object({
  distributionListId: Joi.string().min(1),
  subjectSearch: Joi.string().min(1)
}).or('subjectSearch', 'distributionListId');

export const useGetDistLists = (): {
    loadDistlistData: DistributionList[] | undefined,
    loadDistlistError: Error | undefined,
    loadDistlistIsLoading: boolean } => {

  const { data, error, isLoading} = useSWR(
    DIST_LISTS_CACHE,
    () => DistListApiService.GetDistists(),
    { revalidateOnFocus: false }
  );

  return { loadDistlistData : data, loadDistlistError : error, loadDistlistIsLoading:  isLoading};
};

export const useSearchMessages = (dlsr: apiSearchSecureEmailRequest):
  { searchResults: DistributionListMessagesSearchResponse[] | undefined,
    searchError: ResponseError,
    searchIsLoading: boolean,
    searchMutate: MutatorCallback<DistributionListMessagesSearchResponse[]> } => {

  const shouldFetch = searchValidator.validate(dlsr);

  const { data, error, isLoading, mutate } = useSWR(
    !shouldFetch.error ? dlsr : null,
    DistListApiService.SearchMessages,
    { revalidateOnFocus: false }
  );

  return {
    searchResults: data,
    searchError: error,
    searchIsLoading: isLoading,
    searchMutate: mutate,
  };
};

export const useGetDistListById = (id?: string): {
  data: DistributionListResponseSingle | undefined,
  error: Error | undefined,
  isLoading: boolean } => {
  const shouldFetch = id !== undefined;
  const { data, error, isLoading} = useSWR(
    shouldFetch ? `DistributionLists/${ id }` : null,
    () => DistListApiService.GetDististById(id),
    { revalidateOnFocus: false }
  );

  return { data, error, isLoading};
};

export const useCreateUpdateDistList = (): {
  data: DistributionListResponseSingle | undefined;
  trigger: (payload: apiDistributionListUpdateRequest | any) => Promise<DistributionListResponseSingle>;
  isMutating: boolean;
} => {
  const { mutate } = useSWR(DIST_LISTS_CACHE);
  const { cache } = useSWRConfig();

  const { trigger, data, isMutating } = useSWRMutation(
    DIST_LISTS_CACHE,
    DistListApiService.CreateUpdateDistList,
    {
      onSuccess: c => {
        //	Mutate the cache with new or updated data
        const { data } = cache.get(DIST_LISTS_CACHE) as State<DistributionListResponseSingle[]>;

        if (!data || !c) {
          return;
        }

        const index: number = data.findIndex(i => i.id === c.id);

        if (index === -1) { // New
          mutate([DistListApiService.updateDistListsResponse(c), ...data], { revalidate: false });
        } else { // Updated
          mutate(replaceItemAt(data, DistListApiService.updateDistListsResponse(c), index), { revalidate: false });
        }
      }
    }
  );

  return { trigger, data, isMutating };
};

export const useGetRecipients = (): { 
  trigger: (arg: { id: string, distributionListId: string; }) => Promise<DistributionListMessagesSearchResponse>; 
  error: AxiosError; 
  isMutating: boolean; } => {
  
  const { trigger, error, isMutating } = useSWRMutation(
    'dist-list-emial-reciepients',
    DistListApiService.GetEmailRecipients,
    { revalidate: false }
  );

  return { trigger, error, isMutating };
}

export class DistListApiService
{
  static CreateUpdateDistList = (url: string, params: { arg: apiDistributionListUpdateRequest }) => {
    const data = params.arg;
    const method: string = 'PUT';

    return axios.request<any, AxiosResponse<apiDistributionListSummary>>({
      url: 'DistributionLists',
      method: method,
      data: data
    })
    .then(r => parsePropsToDateTime<DistributionListResponseSingle>(r.data, ['lastEmailDate']))
    .catch(e => {
       console.error('Error', e);
      ErrorToastService.handleError(e, [400, 403, 500, 503]);
      throw(e);
    });
  };

  // Not used for now, for future use if we want to save only recipients
  static UpdateDistListRecipients = (url: string, params: { arg: { id: string, recipients: string[] }}): Promise<DistributionList | void> => {
    const data = params.arg;
    const method: string = 'PATCH';

    return axios.request({
      url: `DistributionLists/${ data.id }/Recipients`,
      method: method,
      data: data.recipients
    })
      .then(r => r?.data as DistributionList)
      .catch(e => {
        console.error('Error', e);
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
        return;
      });
  };

  static GetDistists = () => axios.request<any, AxiosResponse<apiDistributionListSummary[]>>({
    url: 'DistributionLists',
    method: 'GET'
  })
  .then(r => r.data.map((el) => parsePropsToDateTime<DistributionList>(el, ['lastEmailDate'])))
  .catch(e => {
    ErrorToastService.handleError(e, [500, 503]);
    throw e;
  });

  static GetDististById = (id?: string) => axios.request<any, AxiosResponse<DistributionListResponseSingle>>({
    url: `DistributionLists/${ id }`,
    method: 'GET'
  })
  .then(r => r.data)
  .catch(e => {
    ErrorToastService.handleError(e, [500, 503]);
    throw e;
  });

  static SearchMessages = (data: apiSearchSecureEmailRequest) => axios.request<any, AxiosResponse<DistributionListMessagesSearchResponse[]>>({
    url: 'mail/email/search',
    data,
    method: 'POST'
  })
    .then(r => r.data.map(this.updateSearchMessagesResponse))
    .catch(e => {
      ErrorToastService.handleError(e, [400, 500, 503]);

      throw e;
    });

  static Resend = (params: ResendParams): Promise<ResendParams | null> =>
    axios<ResendParams>({
      url: 'mail/email/resend',
      data: params,
      method: 'POST'
    })
      .then(() => params)
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);
        return null;
      });

  static GetEmailRecipients = async (url: string, params: { arg: { id: string; distributionListId: string; }; }) => {
    return axios.request<any, AxiosResponse<DistributionListMessagesSearchResponse[]>>({
      url: 'mail/email/search/recipients',
      method: 'POST',
      data: params.arg
    })
    .then(results => parsePropsToDateTime<DistributionListMessagesSearchResponse>(results.data, ['messageDate']))
    .catch(e => {
      throw e;
    });
  }

  static updateSearchMessagesResponse =
    (r:DistributionListMessagesSearchResponse, index: number):DistributionListMessagesSearchResponse => ({
      ...r,
      messageDate: DateTime.fromISO(`${ r.messageDate }`).toUTC(),
    });

  static EmptyRequest = (): DistributionListCreateRequest => ({
    id: undefined,
    name: '',
    linkedCldd: '',
    recipients: [],
    comments: ''
  });

  static updateDistListsResponse =
    (r:DistributionListResponseSingle):DistributionListResponseSingle => ({
      ...r,
      recipientsCount: r.recipients.length,
    });

}