import { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import clsx from 'clsx';
import { Button } from 'primereact/button';

import { SurveillanceModeEnum } from '../../../../Models/Enums';
import {
  ContentComponentMessage,
  ContentComponentProps,
  DetailsPanelState,
  RequestSiblingMessagesDirection,
  type SelectedContentMessage,
  TranscriptionState,
} from '../../Models';
import { isMessagesEqual } from '../../Models/Helpers';
import NoMessagesInfoComponent from '../NoMessagesInfo/NoMessagesInfo';
import TranscriptionComponent from '../TranscriptionComponent';

import styles from './ContentComponent.module.scss';

const filterMessages = (
  messages: SelectedContentMessage[],
  filterMessages: SelectedContentMessage[]
): SelectedContentMessage[] =>
  messages.filter(m => !filterMessages.find(fm => isMessagesEqual(m, fm)));

const Content = (
  {
    worksheetId,
    mainMessage,
    panelState,
    className,
    messagesPerPage,
    messages,
    selectedMessages,
    setSelectedMessages,
    selectedSiblingMessages,
    hasNoNextMessages,
    hasNoPrevMessages,
    setMsgDirection,
    setPanelState,
    onTranscriptionState,
    saveTranscriptionVersion,
    setSelectedSiblingMessages,
    scrollExpandedDetailsRef,
    resultsMode,
  }: ContentComponentProps,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const [editingTranscriptionId, setEditingTranscriptionId] = useState<
    string | null
  >(null);

  const onSelectionChanged = useCallback(
    (message: ContentComponentMessage, state: boolean): void => {
      // sibling message state has been changed
      if (!isMessagesEqual(message, mainMessage)) {
        const changedMessage: SelectedContentMessage = {
          ...message,
          parent: mainMessage,
        };
        const newMessagesSelection: SelectedContentMessage[] = [];
        if (state) {
          newMessagesSelection.push(
            ...[
              ...filterMessages(selectedSiblingMessages || [], [
                changedMessage,
              ]),
              changedMessage,
            ]
          );
          // select main message as well
          setSelectedMessages &&
            setSelectedMessages(messages => [
              ...messages.filter(m => !isMessagesEqual(m, mainMessage)),
              mainMessage,
            ]);
        } else {
          newMessagesSelection.push(
            ...[
              ...filterMessages(selectedSiblingMessages || [], [
                changedMessage,
              ]),
            ]
          );
        }
        setSelectedSiblingMessages &&
          setSelectedSiblingMessages(newMessagesSelection);
      }
      // main message state has been changed
      else {
        if (state) {
          // select main message only
          setSelectedMessages &&
            setSelectedMessages(messages => [
              ...messages.filter(m => !isMessagesEqual(m, mainMessage)),
              mainMessage,
            ]);
        } else {
          // unselect main message
          setSelectedMessages &&
            setSelectedMessages(messages => [
              ...messages.filter(m => !isMessagesEqual(m, mainMessage)),
            ]);
          // unselect child messages
          setSelectedSiblingMessages &&
            setSelectedSiblingMessages(messages => [
              ...messages.filter(
                m => m.parent && !isMessagesEqual(m.parent, mainMessage)
              ),
            ]);
        }
      }
    },
    [
      mainMessage,
      selectedSiblingMessages,
      setSelectedMessages,
      setSelectedSiblingMessages,
    ]
  );

  const onTranscriptionStateChanged = useCallback(
    (message: ContentComponentMessage, state: TranscriptionState): void => {
      setEditingTranscriptionId(
        state === TranscriptionState.Edit ? message.id : null
      );
      onTranscriptionState && onTranscriptionState(message.id, state);
    },
    [onTranscriptionState]
  );

  return (
    <div ref={ref} className='grow-to-fill direction--column'>
      <header
        className={clsx(
          styles.contentHeader,
          editingTranscriptionId ? 'hidden' : ''
        )}
      >
        <span>Content</span>
        <Button
          size='small'
          label={`prv ${ messagesPerPage }`}
          disabled={hasNoPrevMessages || false}
          onClick={(): void =>
            setMsgDirection &&
            setMsgDirection(RequestSiblingMessagesDirection.Previous)
          }
          text
        />
        <Button
          size='small'
          label={`nxt ${ messagesPerPage }`}
          disabled={hasNoNextMessages || false}
          onClick={(): void =>
            setMsgDirection &&
            setMsgDirection(RequestSiblingMessagesDirection.Next)
          }
          text
        />
        {panelState === DetailsPanelState.collapsed &&
          resultsMode !== SurveillanceModeEnum.Escalated && (
          <Button
            size='small'
            icon='iconoir-expand icon--tiny icon--ob-orange'
            text
            onClick={(): void =>
              setPanelState && setPanelState(DetailsPanelState.expanded)
            }
          />
        )}
        {panelState === DetailsPanelState.expanded && (
          <Button
            size='small'
            icon='iconoir-collapse icon--tiny icon--ob-orange'
            text
            onClick={(): void =>
              setPanelState && setPanelState(DetailsPanelState.collapsed)
            }
          />
        )}
      </header>
      <div
        className={clsx(
          panelState === DetailsPanelState.expanded ? styles.scroll : undefined,
          'grow-to-fill'
        )}
      >
        <div
          ref={scrollExpandedDetailsRef}
          className={clsx(styles.gap, 'direction--column grow-to-fill')}
        >
          {hasNoPrevMessages && !editingTranscriptionId && (
            <NoMessagesInfoComponent />
          )}
          <div
            className={clsx(
              className,
              styles.gap,
              'grow-to-fill direction--column'
            )}
          >
            {messages?.map(message => (
              <TranscriptionComponent
                key={message.id}
                className={
                  editingTranscriptionId &&
                  message.id !== editingTranscriptionId
                    ? 'hidden'
                    : ''
                }
                message={message}
                isMain={isMessagesEqual(message, mainMessage)}
                isMessageSelected={Boolean(
                  selectedSiblingMessages?.find(m =>
                    isMessagesEqual(m, message)
                  ) ||
                    (isMessagesEqual(message, mainMessage) &&
                      selectedMessages?.find(m => isMessagesEqual(m, message)))
                )}
                worksheetId={worksheetId}
                onTranscriptionStateChanged={onTranscriptionStateChanged}
                saveTranscriptionVersion={saveTranscriptionVersion}
                onSelectionChanged={(state): void =>
                  onSelectionChanged(message, state)
                }
              />
            ))}
          </div>
          {hasNoNextMessages && !editingTranscriptionId && (
            <NoMessagesInfoComponent />
          )}
        </div>
      </div>
    </div>
  );
};

const ContentComponent = forwardRef<HTMLDivElement, ContentComponentProps>(
  Content
);

export { ContentComponent };
export default ContentComponent;
