export enum Group {
  Instrument,
  Buyer,
  Seller,
  Price,
  Quantity,
  'OB Broker'
}

// local only
export enum BlotterEntitySearchFieldsEnum {
  Price = -1,
  Quantity = -2
}

export enum ValidationMode {
  None,
  All,
  CompanyOnly,
  TouchedOnly,
}

export enum SortOrder {
  Asc = 1,
  Desc
}

export enum SortableFieldName {
  Instrument = 'Instrument',
  DateTime = 'DateTime',
  BuyerObBrokerUserName = 'Buyer/ObBroker/UserName',
  BuyerContactName = 'Buyer/ContactName',
  SellerObBrokerUserName = 'Seller/ObBroker/UserName',
  SellerContactName = 'Seller/ContactName',
  QuantityAmount = 'Quantity/Amount',
  PriceAmount = 'Price/Amount',
  ClearingId = 'Clearing/Id' // TODO: update?
}

export enum TradeType {
  Spread = 'Spread',
  Outright = 'Outright'
}