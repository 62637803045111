import clsx from 'clsx';

import AudioPlayer from 'components/AudioPlayer';

import {
  AttachmentItem,
  AttachmentSectionProps,
  AttachmentType,
} from '../../../../Models';

import AttachmentComponent from './AttachmentComponent';

import { uniqueId } from 'helpers/Utils/string';

import styles from './AttachmentsSection.module.scss';

const getAttachmentComponent = (
  attachment: AttachmentItem,
  index: string | number = uniqueId()
): React.ReactNode => {
  switch (attachment.type) {
    case AttachmentType.Audio:
      return <AudioPlayer key={index} source={attachment.source} />;
    default:
      return <AttachmentComponent key={index} {...attachment} />;
  }
};

const AttachmentsSection = ({
  className = '',
  header = '',
  data = [],
}: AttachmentSectionProps): JSX.Element => (
  <div className={clsx(styles.container, className)}>
    <label>{header}</label>
    {data && Array.isArray(data)
      ? data.map(getAttachmentComponent)
      : getAttachmentComponent(data)}
  </div>
);

export { AttachmentsSection };
export default AttachmentsSection;
