import { useCallback, useMemo, type UIEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DataTable, type DataTableStateEvent, type DataTablePropsSingle, type DataTableSelectionSingleChangeEvent } from 'primereact/datatable';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { type VirtualScrollerChangeEvent } from 'primereact/virtualscroller';
import clsx from 'clsx';

import { ServiceCallError } from 'components/Errors/ServiceCalls';
import { DoubleLineSimple, ReadableDate, SingleLineWithAddon } from 'helpers/DataTable/Templates/ColumnTemplates';
import { ServerSortHeader } from 'helpers/DataTable/Templates/Headers';
import TradeItem from 'modules/Blotter/Components/TradeDetails/Templates/TradeItem';
import { formatName } from 'helpers/Utils/string';
import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';
import { DEFAULT_GRID_ROW_HEIGHT } from 'models/shared/consts';
import { MOBILE_GRID_ROW_HEIGHT } from 'modules/Blotter/Models/Consts';
import { SortableFieldName, SortOrder } from 'modules/Blotter/Models/Enums';

import type { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';
import type { SortByFields } from 'modules/Blotter/Models/SearchRequest';
import type { AxiosError } from 'axios';

import './BlotterDataTable.scss';

const EMPTY_MESSAGE = 'Sorry. No matches for that search…';

interface BlotterDataTableProps {
  isLoading: boolean;
  onLazyLoad: (e: VirtualScrollerChangeEvent) => void;
  onTradeSelected: (arg: TradesDataResponse) => void;
  onTableScroll: (e: UIEvent<HTMLElement>) => void;
  selectedTrade: TradesDataResponse | null;
  trades: TradesDataResponse[] | undefined;
  orderByColumns: SortByFields[];
  setOrderByColumns: (arg: SortByFields[]) => void;
  error?: AxiosError;
};

export default function BlotterDataTable(props: BlotterDataTableProps): JSX.Element {
  const { error, isLoading, onTradeSelected, selectedTrade, onLazyLoad, trades, onTableScroll, orderByColumns, setOrderByColumns } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const handleOrderChange = useCallback((event: DataTableStateEvent) => {
    let newSortOrder: SortByFields[];
    const sortField = event.sortField as SortableFieldName;
    const index = orderByColumns.findIndex(item => item.fieldName === sortField);
    const item = orderByColumns[index];

    //  This sortField doesn't exist in our ordering - so add it
    if (!item) {
      newSortOrder = [...orderByColumns, { fieldName: sortField, sortOrder: SortOrder.Asc }];
    } else {
      switch (item.sortOrder) {
        case SortOrder.Asc:
          //  Switch to descending
          newSortOrder = replaceItemAt<SortByFields>(orderByColumns, { fieldName: sortField, sortOrder: SortOrder.Desc }, index);
          break;
        case SortOrder.Desc:
          //  …or remove completely
          newSortOrder = removeItemAt<SortByFields>(orderByColumns, index);
          break;
        default:
          newSortOrder = orderByColumns;
      }
    }

    setOrderByColumns(newSortOrder);
  }, [orderByColumns, setOrderByColumns]);

  const sortOrder = useMemo(
    // map order to match <ServerSortHeader /> `current` prop type
    () => (orderByColumns ?? []).map(i => `${i.fieldName} ${SortOrder[i.sortOrder].toLowerCase()}`),
    [orderByColumns]
  );

  if (error) {
    return <ServiceCallError error={error} />;
  }

  const handleRowClick = (value: TradesDataResponse): void => {
    onTradeSelected(value);
  };

  const COMMON_PROPS = {
    className: 'blotter-data-table',
    dataKey: 'id',
    emptyMessage: EMPTY_MESSAGE,
    loading: isLoading,
    metaKeySelection: false,
    onSelectionChange: (e: DataTableSelectionSingleChangeEvent<TradesDataResponse[]>): void => onTradeSelected(e.value),
    scrollable: true,
    scrollHeight: 'flex',
    selection: selectedTrade,
    value: trades,
    virtualScrollerOptions: {
      autoSize: true,
      className: 'grow-to-fill',
      itemSize: DEFAULT_GRID_ROW_HEIGHT, // itemSize is required to display proper amount of items
      onLazyLoad,
      lazy: true
    }
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  const MOBILE_PROPS = {
    cellClassName: () => 'no--padding',
    className: clsx(COMMON_PROPS.className, 'row--no-border'),
    selectionMode: 'single' as DataTablePropsSingle<TradesDataResponse[]>['selectionMode'],
    showHeaders: false,
    virtualScrollerOptions: {
      ...COMMON_PROPS.virtualScrollerOptions,
      itemSize: MOBILE_GRID_ROW_HEIGHT, // itemSize is required to display proper amount of items
      onScroll: onTableScroll,
    },
    children: <Column body={(data: TradesDataResponse): JSX.Element => TradeItem({ data, handleRowClick })} />
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  const DESKTOP_PROPS = {
    selectionMode: 'single',
    onSort: handleOrderChange,
    children: [
      <Column
        key='instrument'
        field='instrument'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Instrument'
          by={SortableFieldName.Instrument}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.Instrument}
      />,
      <Column
        body={(data: TradesDataResponse) => data.isImported ? data.buyerObBroker.userName : formatName(data.buyerObBroker.userName)}
        key='buyerObBroker.userName'
        field='buyerObBroker.userName'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Broker (Buy)'
          by={SortableFieldName.BuyerObBrokerUserName}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.BuyerObBrokerUserName}
      />,
      <Column
        body={DoubleLineSimple}
        key='buyerContactName,buyerCompany'
        field='buyerContactName,buyerCompany'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Buy Side'
          by={SortableFieldName.BuyerContactName}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.BuyerContactName}
      />,
      <Column
        body={(data: TradesDataResponse) => data.isImported ? data.sellerObBroker.userName : formatName(data.sellerObBroker.userName)}
        key='sellerObBroker.userName'
        field='sellerObBroker.userName'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Broker (Sell)'
          by={SortableFieldName.SellerObBrokerUserName}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.SellerObBrokerUserName}
      />,
      <Column
        body={DoubleLineSimple}
        key='sellerContactName,sellerCompany'
        field='sellerContactName,sellerCompany'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Sell Side'
          by={SortableFieldName.SellerContactName}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.SellerContactName}
      />,
      <Column
        body={(data: TradesDataResponse, config: ColumnBodyOptions) => SingleLineWithAddon(data, config, data.quantity.unit)}
        bodyClassName='no--padding blotter-data-table__column--quantity'
        key='quantity.amount'
        field='quantity.amount'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Quantity'
          by={SortableFieldName.QuantityAmount}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.QuantityAmount}
      />,
      <Column
        body={(data: TradesDataResponse, config: ColumnBodyOptions) => SingleLineWithAddon(data, config, data.price.unit)}
        bodyClassName='no--padding blotter-data-table__column--price'
        key='price.amount'
        field='price.amount'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Price'
          by={SortableFieldName.PriceAmount}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.PriceAmount}
      />,
      <Column
        body={(data: TradesDataResponse, config: ColumnBodyOptions) => ReadableDate(data, config, 'dd LLL yyyy, HH:mm ZZZZ')}
        key='dateTime'
        field='dateTime'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Trade Date, Time'
          by={SortableFieldName.DateTime}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.DateTime}
      />,
      <Column
        key='clearingId'
        field='clearingId'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Clearing ID'
          by={SortableFieldName.ClearingId}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.ClearingId}
      />
    ]
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  return <DataTable
    key={`${isMobile}`}
    {...COMMON_PROPS}
    {...(isMobile ? MOBILE_PROPS : DESKTOP_PROPS)}
  />;
}