import { ColumnBodyOptions } from 'primereact/column';

import GridElement from 'components/GridElement';
import { DoubleLineSimple, ReadableDate, SingleLineWithAddon } from 'helpers/DataTable/Templates/ColumnTemplates';
import { formatName } from 'helpers/Utils/string';

import type { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';

interface TradeItemProps {
  data: TradesDataResponse;
  handleRowClick: (value: TradesDataResponse) => void;
}

export default function TradeItem(props: TradeItemProps): JSX.Element {
  const { data, handleRowClick } = props;

  return <div className='trade-item' onClick={(): void => handleRowClick(data)}>
    <GridElement className='grid-element__instrument' header='Instrument' body={data.instrument} />
    <GridElement header='Broker (Buy)' body={data.isImported ? data.buyerObBroker.userName : formatName(data.buyerObBroker.userName)} />
    <GridElement header='Buy Side' body={DoubleLineSimple(data, { field: 'buyerContactName,buyerCompany' } as ColumnBodyOptions)} />
    <GridElement header='Broker (Sell)' body={data.isImported ? data.sellerObBroker.userName : formatName(data.sellerObBroker.userName)} />
    <GridElement header='Sell Side' body={DoubleLineSimple(data, { field: 'sellerContactName,sellerCompany' } as ColumnBodyOptions)} />
    <GridElement className='grid-element__quantity' header='Quantity' body={SingleLineWithAddon(data, { field: 'quantity.amount' } as ColumnBodyOptions, data.quantity.unit)} />
    <GridElement className='grid-element__price' header='Price' body={SingleLineWithAddon(data, { field: 'price.amount' } as ColumnBodyOptions, data.price.unit)} />
    <GridElement header='Trade Date, Time' body={ReadableDate(data, { field: 'dateTime' } as ColumnBodyOptions, 'dd LLL yyyy, HH:mm ZZZZ')} />
    <GridElement header='Clearing ID' body={data.clearingId} />
  </div>;
}